
.device-popup-window {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 400px;
    padding: 10px 0px 0px 0px;
    font-family: -apple-system, "SF UI Text", "Helvetica Neue", Arial, "PingFang SC", "Hiragino Sans GB", "Source Han Sans CN", "Microsoft YaHei", "WenQuanYi Zen Hei", sans-serif;
    font-size: 12px;
    line-height: 140%;

    .group-title {
        font-size: 14px;
        padding: 5px 0px 5px 10px;
        border-bottom: 1px solid #AAAAAA;
    }

    .group-content {
        padding: 0px;
    }

    .device-details-wrapper {
        display: flex;
        flex-direction: row;
        padding: 10px 10px;

        .field-column {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            width: 50%;
            box-sizing: border-box;
        }
    }

    .field-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 40px;
        font-size: 14px;
    }

    .field-label {
        white-space: nowrap;

        &::after {
            content: ': ';
            margin-right: 5px;
        }
    }

    .field-value {
        white-space: nowrap;
    }

    .view-details {
        cursor: pointer;
        font-size: 12px;
        color: #AAAAAA;

        &:hover {
            color: #FFFFFF;
        }
    }

    .device-metrics-wrapper {
        display: grid;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        grid-gap: 5px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-auto-rows: minmax(50px, 1fr);
        align-items: stretch;
        justify-items: stretch;
        padding: 10px 0px 0px 0px;
        box-sizing: border-box;
    }

    .property-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 5px;

        &:hover {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }

    .property-name {
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 12px;
        z-index: 100;
        color: rgba(255, 255, 255, 0.7);
    }

    .property-value {
        font-size: 18px;
        z-index: 100;
    }

    .property-chart {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 99;
    }
}

.online-circle {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgba(28, 178, 115, 0.5);
    position: relative;
    
    .waves {
        position: absolute;
        display: inline-block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.2);
        animation: wave ease-out infinite 2s 0s;
        transform-origin: center center;
    }
}

.offline-circle {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgba(249, 125, 90, 0.5);
    position: relative;
}

@keyframes wave {
    0% {
        transform: scale(1.0);
        opacity: 1.0;
    }
    50% {
        transform: scale(3.0);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}