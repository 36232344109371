@font-face {
    font-family: '优设标题黑';
    src: url('../public/static/font/YouSheBiaoTiHei.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rajdhani';
    src: url('../public/static/font/Rajdhani-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rajdhani';
    src: url('../public/static/font/Rajdhani-Medium.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Orbitron';
    src: url('../public/static/font/Orbitron-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DS-DIGIT';
    src: url('../public/static/font/DS-DIGIT.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

html {
    font-size: 12px;
    line-height: 1.2;
}

html body {
    font-family: -apple-system, "SF UI Text", "Helvetica Neue", Arial, "PingFang SC", "Hiragino Sans GB", "Source Han Sans CN", "Microsoft YaHei", "WenQuanYi Zen Hei", sans-serif;
    letter-spacing: 1px;
    margin: 0px;
    padding: 0px;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    outline: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body .ant-btn {
    padding: 20px 20px !important;
}

body .ant-table-cell {
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ant-popconfirm-buttons .ant-btn {
    padding: 5px 10px !important;
    height: auto !important;
}

body .ant-drawer:focus-visible {
    outline: none !important;
}

body .adm-picker-header-button {
    color: #333333 !important;
}

body .adm-dialog-button {
    color: #333333 !important;
}

.amap-logo {
    display: none;
    opacity: 0 !important;
}

.amap-copyright {
    opacity: 0;
}

.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
