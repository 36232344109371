.scroll-x-fade-transition-enter {
    opacity: 0;
    transform: translateX(-20%);
    transition: all 300ms ease-in;
}

.scroll-x-fade-transition-enter-active {
    opacity: 0;
    transform: translateX(-20%);
    transition: all 300ms ease-in;
}

.scroll-x-fade-transition-enter-done {
    opacity: 1;
    transform: translateX(0px);
    transition: all 300ms ease-in;
}

.scroll-x-fade-transition-exit {
    opacity: 1;
    transform: translateX(0px);
    transition: all 300ms ease-in;
}

.scroll-x-fade-transition-exit-active {
    opacity: 0;
    transform: translateX(30%);
    transition: all 300ms ease-in;
}


.scroll-x-fade-transition-exit-done {
    opacity: 0;
    transform: translateX(60%);
    transition: opacity 300ms, transform 300ms;
}
